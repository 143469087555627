
const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Vespid Privacy Policy</h1>
      <p className="text-sm text-gray-500">Last updated 20 August 2022</p>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Personal Data Protection Act (PDPA)</h2>
        <p>
          Pursuant to the requirement of the Personal Data Protection Act 2010 (hereinafter referred to as the “Act”), we hereby issue this notice to inform and explain how Vespid collects, uses, maintains, and discloses your personal data in respect of commercial transactions. This personal data notice applies to any person whose personal data is processed by Vespid.
        </p>
        <p className="mt-4">
          “Vespid”, “our”, “us”, or “we” in this notice refers to Vespid MY Solutions, including its branches, subsidiaries, and related companies.
        </p>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">A. Nature of Personal Data</h2>
        <p>
          The personal data processed by us may include your name, contact details, email address, job title, and company name, images, age, gender, date of birth, national registration identity card number/passport number; race, religion, nationality, information on any past breaches of or convictions under any law, and any other personal data provided by you required for the purposes set out below.
        </p>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">B. Purpose of Vespid Collecting Your Personal Data</h2>
        <p>Subject to the relevant laws, Vespid may use your personal data for the following purposes:</p>
        <ul className="list-disc ml-6 mt-4">
          <li>To enable it to discharge its duties and obligations under the Capital Markets and Services Act 2007, the Securities Commission Act 1993, the Securities Industry (Central Depositories Act) 1991, the rules of Bursa Malaysia Sdn Bhd, or any other written law;</li>
          <li>To enable it to discharge its contractual obligations;</li>
          <li>To verify your financial standing through credit reference checks;</li>
          <li>To respond to your enquiries and complaints and to resolve disputes;</li>
          <li>To be used for internal record-keeping;</li>
          <li>To provide ongoing information about our products and services to people that we believe may be interested in such events, programs, products, and services;</li>
          <li>To provide services;</li>
          <li>To research, develop, and improve our products and services;</li>
          <li>For employment opportunities in the event you have provided information for such purposes;</li>
          <li>Such other purposes relevant to the products and services provided by Vespid.</li>
        </ul>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">C. Disclosure of Your Personal Data</h2>
        <p>Your personal data may be disclosed to:</p>
        <ul className="list-disc ml-6 mt-4">
          <li>Our subsidiaries, related and associated companies;</li>
          <li>The Bursa Malaysia, Securities Commission, Bank Negara Malaysia, the Malaysian Anti-Corruption Commission, Companies Commission of Malaysia, Registrar of Societies, and other supervisory, governmental, or relevant authorities;</li>
          <li>Credit reference agencies when you apply for any of our credit-based products such as margin facilities, collateral accounts, etc.;</li>
          <li>Our co-organizers of events, business partners, and service providers;</li>
          <li>Auditors, solicitors, professional firms, or entities;</li>
          <li>Parties authorized by you;</li>
          <li>Any other person which Vespid may think fit.</li>
        </ul>
        <p className="mt-4">
          Your personal data may be transferred or disclosed to a place outside of Malaysia, and by providing us your personal data you agree to such a transfer where it is required to provide you the services you have requested and for the performance of any contractual obligations you have with Vespid including for storage purposes.
        </p>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">D. Security</h2>
        <p>
          The security of your personal data is our priority. We undertake, where practicable, to implement the appropriate policy and procedures in accordance with the applicable laws and regulations to safeguard your personal data from unauthorized or unlawful use.
        </p>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">E. Access and Correction to Your Personal Data</h2>
        <p>You may access and request correction of your personal data in which you have provided to Vespid and in this respect, you may:</p>
        <ul className="list-disc ml-6 mt-4">
          <li>Check if we hold or use your personal data and request access to your personal data;</li>
          <li>Request correction of any of your personal data which is inaccurate, incomplete, or not up to date;</li>
          <li>Request Vespid to limit the processing of your personal data, including personal data limiting to the persons who may be identified from your personal data;</li>
          <li>Inform Vespid of your withdrawal (in full or in part) of your consent given previously to Vespid subject to any applicable legal restrictions, contractual conditions, and a reasonable duration of time for the withdrawal of consent to be effected.</li>
        </ul>
        <p className="mt-4">
          Please be informed we may charge a small fee depending on the information requested. We may also take steps to verify your identity before fulfilling your request for access to your personal data.
        </p>
        <p className="mt-4">
          Please contact us using any of the following modes if you have any enquiries or complaints in respect of your personal data:
        </p>
        <address className="mt-4">
          <strong>Designated Contact Person:</strong> Chief Executive Officer (CEO)<br/>
          <strong>Mailing Address:</strong> 11A, Jalan USJ 11/4J, 47620 Subang Jaya, Selangor<br/>
          <strong>Telephone No.:</strong> +6 0168519438<br/>
          <strong>Email Address:</strong> vespid.my@gmail.com
        </address>
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">F. Failure of Providing Your Personal Data</h2>
        <p>
          Unless otherwise specified by us at the time the personal data is collected, it is obligatory that you supply us with the personal data requested by us. If you refuse to supply us with the above personal data, we may not be able to carry out the purposes for which you have provided us the personal data and, in addition, you may be in breach of the requirements of the relevant rules.
        </p>
        <p className="mt-4">
          By providing us your personal data, you hereby consent to the processing of your personal data in accordance with all of the above.
        </p>
      </section>
    </div>
  );
}

export default Privacy;
