const Disclaimer = ({ setOpenDisclaimer }) => {
  return (
    <div className="z-50 mx-2 fixed border-2 border-primaryYellow rounded-lg bg-white bottom-20 py-4 px-4">
      <h1 className="font-bold text-center">Disclaimer</h1>
      <p className="text-xs mt-4 text-left">
        Vespid does not recommend any securities. The above content is provided
        for general informational purposes only. It is not intended to
        constitute investment advice or any other kind of professional advice
        and should not be relied upon as such. Before taking action based on any
        such information, we encourage you to consult with the appropriate
        professionals. We do not endorse any third parties referenced within the
        article. Market and economic views are subject to change without notice
        and may be untimely when presented here. Do not infer or assume that any
        securities, sectors or markets described in this article were or will be
        profitable.
      </p>
      <button
        onClick={() => setOpenDisclaimer(false)}
        className="text-sm text-green-600 mt-2 px-2"
      >
        I have read and understand the Disclaimer
      </button>
    </div>
  );
};

export default Disclaimer;
