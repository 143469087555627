import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const contextClass = {
  success: "bg-white border-2 border-green-400",
  error: "bg-white border-2 border-red-400",
};

class NotificationHandler {
  success(message) {
    return toast.success(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  error(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export const Notification = new NotificationHandler();
