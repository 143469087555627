import { useRef } from "react";
import support from "../assets/Support.png";
import emailjs from "@emailjs/browser";
import { IoLogoInstagram, IoLogoTwitter, IoLogoFacebook } from "react-icons/io";
import { Notification } from "../core/notification_handler";
import LazyLoad from 'react-lazyload';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gatasgr",
        "template_cqt5xmd",
        form.current,
        "user_B4ameb3SXSGMi12QqLDkq"
      )
      .then(() =>
        Notification.success(
          "Your message has been sent! We will respond to you at the earliest convenience."
        )
      )
      .catch(() =>
        Notification.error(
          "Submission error. Contact Vespid at vespid.my@gmail.com for support."
        )
      );
  };

  return (
    <div className="max-w-screen-xl lg:mx-auto font-primaryFont font-light px-8">
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-1/2 md:mt-20 mx-4">
          <h1 className="mt-4 text-center text-2xl">Contact us</h1>
          <form
            id="contact-form"
            ref={form}
            onSubmit={sendEmail}
            className="mt-4 flex flex-col justify-center items-center w-full border shadow-lg px-2 py-4 rounded-lg"
          >
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              className="px-2 mt-4 text-lg text-center border-b"
            ></input>
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              className="px-2 mt-4 text-lg text-center border-b"
            ></input>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="px-2 mt-4 text-lg text-center border-b"
            ></input>
            <textarea
              type="text"
              name="message"
              placeholder="Your Message"
              className="px-2 w-full text-clip h-32 mt-4 text-lg text-center border"
            ></textarea>
            <input
              type="submit"
              value="Submit"
              className="mt-4 bg-primaryYellow hover:bg-primaryOrange px-4 py-2 rounded"
            ></input>
          </form>
        </div>
        <div className="md:w-1/2 md:relative">
          <LazyLoad>
          <img
            src={support}
            alt="A phone with Vespid app and a mug"
            loading="lazy"
            className="md:absolute md:h-full md:object-cover"
          />
          </LazyLoad>
        </div>
      </div>
      <div className="mt-10 flex flex-row justify-center items-center">
        <a
          href="https://www.instagram.com/vespid_my/"
          target="_blank"
          rel="noreferrer noopener"
          className="mx-2 flex flex-col items-center"
        >
          <IoLogoInstagram size="50" />
          <p class="text-xs">@vespid_my</p>
        </a>
        <a
          href="https://www.facebook.com/vespid.my"
          target="_blank"
          rel="noreferrer noopener"
          className="mx-2 flex flex-col items-center"
        >
          <IoLogoFacebook size="50" />
          <p class="text-xs">Vespid</p>
        </a>
        <button className="mx-2 flex flex-col items-center">
          <IoLogoTwitter size="50" />
          <p class="text-xs">Coming soon</p>
        </button>
      </div>
    </div>
  );
};

export default Contact;
