import { Link } from "react-router-dom";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";

const EduCard = (props) => {
  const { postId, post } = props;
  const { category, title, summary, author, published_date, thumbnail } = post;
  const image = thumbnail.data.attributes.url;
  const categoryValue = category.data.attributes.category;
  const convertedDate = dayjs(published_date).format("DD MMM YYYY");
  console.log(image);
  return (
    <Link to={`/education/${postId}`}>
      <div className="flex flex-col-reverse md:flex-row border-b">
        <div className="md:pr-10 md:w-3/4">
          <p className="text-base md:text-lg">{categoryValue}</p>
          <h2 className="text-lg md:text-xl font-bold">{title}</h2>
          <h3 className="mt-2 text-sm md:text-sm">{summary}</h3>
          <p className="mt-4 text-xs md:text-base">{author}</p>
          <p className="text-xs md:text-base">{convertedDate}</p>
        </div>
        <div className="mb-4 md:w-1/4 flex justify-center">
          <LazyLoad>
          <img
            src={image}
            alt={title}
            loading="lazy"
            className="w-64 md:h-48"
          />
          </LazyLoad>
        </div>
      </div>
    </Link>
  );
};

export default EduCard;
