import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import "./Post.css";

const Post = () => {
  const { id } = useParams();

  const baseURL = "https://vespid-cms.herokuapp.com";
  const [post, setPost] = useState({});
  const [categoryValue, setCategoryValue] = useState("");
  const { title, content, author, published_date } = post;

  const convertedDate = dayjs(published_date).format("DD MMM YYYY");

  useEffect(() => {
    axios
      .get(`${baseURL}/api/educations/${id}`, { params: { populate: "*" } })
      .then((response) => {
        const attr = response.data.data.attributes;
        setPost(attr);
        if ("category" in attr)
          setCategoryValue(attr.category.data.attributes.category);
      });
  }, [id]);

  return (
    <div
      className="mt-10 md:mt-0 md:w-5/12 md:mx-auto flex flex-col px-8 md:px-0 font-sans.
     font-light text-gray-800"
    >
      <div>
        <p className="text-center">{categoryValue}</p>
        <h1 className="mt-2 text-3xl font-bold text-center">{title}</h1>
        <p className="text-center text-sm mb-10">
          <span className="mr-4">{author}</span>
          {convertedDate}
        </p>
        <div id="post-layout">
          <ReactMarkdown children={content} className="mt-16" />
        </div>
      </div>
    </div>
  );
};

export default Post;
