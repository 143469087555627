import { Link } from "react-router-dom";
import { ReactComponent as Computer } from "../assets/computer.svg";
import { ReactComponent as Learn } from "../assets/learn.svg";
import { ReactComponent as Gift } from "../assets/gift.svg";

const About = () => {
  return (
    <div className="max-w-screen-xl lg:mx-auto my-10 px-8 font-primaryFont font-light">
      <div className=" flex flex-col justify-center items-center">
        <h1 className="text-2xl text-center">
          Practice & learn to trade on the Bursa Malaysia stock market
        </h1>
        <p className="mt-4 text-sm md:text-base text-center font-light md:px-44">
          We provide a user-friendly and simplistic practice investing mobile
          app to simulate your earnings and portfolio when investing in the
          Bursa Malaysia stock market
        </p>
        <button className="mt-4 rounded bg-primaryYellow hover:bg-primaryOrange py-2 px-4">
          <Link to="/">Sign up for Beta Version</Link>
        </button>
      </div>
      <div className="mt-20 flex flex-col md:flex-row md:justify-center items-center">
        <Computer className="w-1/2 md:w-4/12" />
        <div className="mt-4 md:w-1/2 md:px-20">
          <h2 className="text-xl text-center">
            Practice trading and investing in real time with no risk
          </h2>
          <p className="mt-2 font-light text-center">
            Vespid has simplistic app design and intuitive user experience for
            beginner and experienced investors alike to simulate the real time
            trading experience
          </p>
        </div>
      </div>
      <div className="mt-10 flex flex-col md:flex-row-reverse md:justify-center items-center">
        <Learn className="w-1/2 md:w-4/12" />
        <div className="md:w-1/2 mt-4 md:px-20">
          <h2 className="text-xl text-center">
            Access to consolidated & FREE learning materials for stock investing
          </h2>
          <p className="mt-2 font-light text-center">
            Follow us on our Instagram page <a
              href="https://www.instagram.com/vespid_my/"
              target="_blank"
              rel="noreferrer noopener"
              className=" text-primaryOrange"
            >@vespid_my</a> or check out our
            <Link to="/Education" class="text-primaryOrange"> Education</Link> portal for FREE bite-sized education materials for
            beginners with zero experience in stock investing
          </p>
        </div>
      </div>
      <div className="mt-10 flex flex-col md:flex-row md:justify-center items-center">
        <Gift className="w-1/2 md:w-4/12" />
        <div className="md:w-1/2 mt-4 md:px-20">
          <h2 className="text-xl text-center">
            Complete quizzes to qualify for rewards and rebates
          </h2>
          <p className="mt-2 font-light text-center">
            Participate in quizzes on our Instagram page and stand a chance to
            win FREE vouchers and rebates. Follow us on our Instagram page
            <a
              href="https://www.instagram.com/vespid_my/"
              target="_blank"
              rel="noreferrer noopener"
              className=" text-primaryOrange"
            >
              {" "}
              @vespid_my
            </a>{" "}
            to begin participating!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
