import { useState } from "react";
import Disclaimer from "./Disclaimer";
import { Link } from "react-router-dom";

const Footer = () => {
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  return (
    <div className="w-full h-17 p-4 bg-primaryYellow text-center font-primaryFont font-light text-gray-800 absolute bottom-0">
      {openDisclaimer ? (
        <Disclaimer setOpenDisclaimer={setOpenDisclaimer} />
      ) : undefined}
      <p className="text-sm">© Copyright 2022 Vespid. All Rights Reserved.</p>
      <button
        className="underline text-sm"
        onClick={() => setOpenDisclaimer(!openDisclaimer)}
      >
        Disclaimer
      </button>
      <Link className="ml-4 underline text-sm" to="/privacy">Privacy Policy</Link>
    </div>
  );
};

export default Footer;
