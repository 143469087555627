import { useState } from "react";
import phone from "../assets/Home.png";
import LazyLoad from 'react-lazyload';
import { Notification } from "../core/notification_handler";
import { db } from '../firebase/config';
import axios from 'axios';
import { addDoc, serverTimestamp, collection, query, where, getDocs } from "firebase/firestore";

const Home = () => {
  const [signupEmail, setSignUpEmail] = useState("");
  const [signUpIsLoading, setSignUpIsLoading] = useState(false);

  // Send sign up email to Beta Test Google Sheets
  const sendEmailToSheets = async () => {
    const sheetsAPI = 'https://sheetdb.io/api/v1/t7fg266ktebhe';
    axios.post(sheetsAPI, { data: { date_time: "DATETIME", first_name: "Vespid", last_name: "Test", email: signupEmail } })
  };
  
  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!signupEmail || signupEmail.length === 0) {
      return Notification.error("Email cannot be empty to sign up.");
    }
    setSignUpIsLoading(true);
    // Assuming you have already initialized your Firebase app and obtained a reference to a Firestore collection
    const signUpsRef = collection(db, "beta_signup");

    // Check if email is unique
    const emailQuery = query(signUpsRef, where("email", "==", signupEmail));
    const querySnapshot = await getDocs(emailQuery);

    try {
      if (querySnapshot.docs.length > 0) {
        Notification.error(
          "Submission error. Your email already signed up for our potential beta users."
        );
      } else {
        const timestamp = serverTimestamp();
        await Promise.all([
          addDoc(signUpsRef, { email: signupEmail, signed_date: timestamp }),
          sendEmailToSheets()
        ])
        Notification.success("You are signed up for potential beta users!");
      }
    } catch (error) {
      Notification.error(
        "Submission error. Please make sure the email format is correct. Contact Vespid at vespid.my@gmail.com for support."
      )
    } finally {
      setSignUpIsLoading(false);
    }

  };
  return (
    <div className="max-w-screen-xl lg:mx-auto font-primaryFont font-light text-gray-800">
      <div className="mt-10 md:mt-0 flex flex-col-reverse md:flex-row justify-center px-8 md:px-0">
        <div className="md:pt-32 md:pr-20 md:w-1/2 text-center md:text-left">
          <h1 className="mt-4 font-bold text-3xl md:text-6xl">Coming Soon</h1>
          <h2 className="text-xl mt-6 ">
            Practice and learn investing in the BURSA Malaysia stock market
          </h2>
          <p className="text-base mt-6">
            We provide a user-friendly and simplistic investing app to simulate
            your earnings and portfolio when investing in the Bursa Malaysia
            stock market
          </p>
          <p className="mt-10 text-sm">Sign up for our beta version.</p>
          <div className="flex flex-col md:flex-row my-6">
            <form onSubmit={sendEmail}>
              <input
                placeholder="Enter your email here"
                name="email"
                value={signupEmail}
                className="border-b-2 md:mr-3 mt-4 md:mt-0 text-center"
                onChange={(e) => setSignUpEmail(e.target.value)}
              ></input>
              <button
                type="submit"
                className="mt-4 rounded px-4 py-2 bg-yellow-400 border border-black"
                disabled={signUpIsLoading}
              >
                {signUpIsLoading ? "Signing up..." : "Sign Up"}
              </button>
            </form>
          </div>
        </div>
        <div className="mt-4 flex justify-center md:w-4/12">
          <LazyLoad>
          <img
            src={phone}
            alt="Vespid iPhone design"
            loading="lazy"
            className="md:h-full md:w-full md:object-cover"
          />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Home;
