import Home from "./screens/Home";


function App() {
  return (
    <Home></Home>
  );
}

export default App;
