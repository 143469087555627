import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import EduCard from "../components/EduCard";

const Education = () => {
  const { postCategory } = useParams();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const baseURL = "https://vespid-cms.herokuapp.com";

  useEffect(() => {
    if (postCategory) {
      axios
        .get(
          `${baseURL}/api/educations?filters[category][category][$eq]=${postCategory}`,
          { params: { populate: "*" } }
        )
        .then((response) => {
          setPosts(response.data.data);
        });
    } else {
      axios
        .get(`${baseURL}/api/educations`, { params: { populate: "*" } })
        .then((response) => {
          setPosts(response.data.data);
        });

      axios
        .get(`${baseURL}/api/categories`, { params: {} })
        .then((response) => {
          setCategories(response.data.data);
        });
    }
  }, [postCategory]);

  return (
    <div className="max-w-screen-xl mt-10 md:mt-0 px-8 md:mx-auto  font-sans font-light text-gray-800">
        <div className="md:w-1/2 md:mx-auto">
        <p className="text-xs text-center">
          All education posts, graphics and images can be found on our Instagram
          page <a
            href="https://www.instagram.com/vespid_my/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-primaryOrange">@vespid_my</a>. Follow us on Instagram for the latest education posts!
        </p>
        {!postCategory ? (
          <div className="mt-4">
            <p className="font-bold">Categories</p>
            <div className="flex flex-wrap">
              {categories.map((category) => {
                const categoryText = category.attributes.category;
                return (
                  <Link
                    className="text-lg md:text-2xl font-bold mx-1 my-1"
                    to={`/education/category/${categoryText}`}
                  >
                    <button className="rounded-lg bg-primaryYellow px-3 py-2 text-xs">
                      {categoryText}
                    </button>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <p className="bg-primaryYellow py-1 px-2 rounded text-center md:w-1/4 md:text-xs">
            {postCategory}
          </p>
        )}

        <div>
          {posts.map((post) => {
            const item = post.attributes;
            return (
              <div className="mt-4" key={post.id}>
                <EduCard postId={post.id} post={item} baseURL={baseURL} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
